import { css } from "@emotion/react";
import { Form, Input, Row, Col, Button, Radio, Space, Select } from "antd";
import { useState } from "react";

import Link from "next/link";

import { message } from "antd";
import SendEnquiry from "lib/api/sendEnquiry";
import { validatePhoneNumber, formatPhoneNumber } from "utils";

import { colourCss, typography } from "config/theme";

const styles = {
    remarkContainer: css`
        text-align: center;
        padding: 16px 0 24px;
    `,
    buttonContainer: css`
        text-align: center;
    `,
    button: css`
        width: 200px;
    `,
    textUnderline: css`
        text-decoration: underline;
    `,
};

const EnquirySendForm = (): JSX.Element => {
    const [loading, setLoading] = useState(false);
    const [valueAboutUs, setValueAboutUs] = useState(0);
    const [form] = Form.useForm();

    const onFinish = (values: {
        name: string;
        email: string;
        phoneNumber: string;
        business: string;
        location: string;
        role: string;
        sports: string;
        aboutUsCategory: number;
        aboutUsPartner: string;
        aboutUsSocialMedia: string;
        aboutUsOthers: string;
        message: string;
    }): void => {
        setLoading(true);
        let aboutUs = "";
        switch (values.aboutUsCategory) {
            case 1:
                aboutUs = `Partner - ${values.aboutUsPartner}`;
                break;
            case 2:
                aboutUs = `Social Media - ${values.aboutUsSocialMedia}`;
                break;
            case 3:
                aboutUs = "Friends";
                break;
            case 4:
                aboutUs = "Online Search";
                break;
            case 5:
                aboutUs = `Others - ${values.aboutUsOthers}`;
                break;
        }
        SendEnquiry({
            senderName: values.name,
            senderEmail: values.email,
            senderPhone: values.phoneNumber,
            senderMessage: `Business: ${values.business}. Location: ${values.location}. Sports: ${values.sports}. Role: ${values.role}. Where: ${aboutUs}. Message: ${values.message}`,
        })
            .then((response) => {
                if (response.ok) {
                    message.success("Enquiry sent successfully");
                    form.resetFields();
                } else {
                    message.error("Failed to send enquiry. Please try again");
                }
            })
            .catch((err) => {
                // eslint-disable-next-line no-console
                console.log(err);
            })
            .finally(() => {
                setLoading(false);
                setValueAboutUs(0);
            });
    };

    return (
        <Form
            form={form}
            onFinish={onFinish}
            onValuesChange={(v) => {
                if ("aboutUsCategory" in v) {
                    setValueAboutUs(v.aboutUsCategory);
                }
            }}
        >
            <Form.Item
                name="name"
                rules={[
                    {
                        required: true,
                        message: "Please enter your name",
                    },
                ]}
            >
                <Input placeholder="Name" size="large" />
            </Form.Item>
            <Row gutter={[24, 0]}>
                <Col xs={12}>
                    <Form.Item
                        name="phoneNumber"
                        normalize={formatPhoneNumber}
                        rules={[
                            {
                                validator: validatePhoneNumber,
                                message: "Invalid Phone Number!",
                            },
                            {
                                required: true,
                                message: "Please input your phone number",
                            },
                        ]}
                    >
                        <Input placeholder="Mobile Number" size="large" />
                    </Form.Item>
                </Col>
                <Col xs={12}>
                    <Form.Item
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: "Please enter your email.",
                            },
                            {
                                type: "email",
                                message: "Please enter a valid email.",
                            },
                        ]}
                    >
                        <Input placeholder="E-mail" size="large" />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[24, 0]}>
                <Col xs={12}>
                    <Form.Item
                        name="business"
                        rules={[
                            {
                                required: true,
                                message:
                                    "Please enter your business/centre name",
                            },
                        ]}
                    >
                        <Input placeholder="Facility Name" size="large" />
                    </Form.Item>
                </Col>
                <Col xs={12}>
                    <Form.Item
                        name="location"
                        rules={[
                            {
                                required: true,
                                message:
                                    "Please enter your business/centre location",
                            },
                        ]}
                    >
                        <Input placeholder="Facility Location" size="large" />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[24, 0]}>
                <Col xs={12}>
                    <Form.Item
                        name="role"
                        rules={[
                            {
                                required: true,
                                message: "Please enter your facility role",
                            },
                        ]}
                    >
                        <Input placeholder="Facility Role" size="large" />
                    </Form.Item>
                </Col>
                <Col xs={12}>
                    <Form.Item
                        name="sports"
                        rules={[
                            {
                                required: true,
                                message:
                                    "Please list sports offered in your business/centre",
                            },
                        ]}
                    >
                        <Input placeholder="Type of sports" size="large" />
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item
                name="aboutUsCategory"
                label="How did you hear about us?"
                rules={[
                    {
                        required: true,
                        message: "Please select a method",
                    },
                ]}
                css={css`
                    .ant-form-item-control-input {
                        padding-left: 16px;
                        flex-direction: column;
                        align-items: flex-start;
                    }
                    .ant-space-item {
                        min-height: 33px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                `}
            >
                <Radio.Group>
                    <Space direction="vertical" align="start">
                        <div>
                            <Radio value={1}>Partner Centres</Radio>
                            {valueAboutUs === 1 ? (
                                <Form.Item
                                    noStyle
                                    name="aboutUsPartner"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Please enter the partner centre",
                                        },
                                    ]}
                                >
                                    <Input
                                        style={{ width: 300, marginLeft: 10 }}
                                        placeholder="Type here"
                                    />
                                </Form.Item>
                            ) : null}
                        </div>
                        <div>
                            <Radio value={2}>Social Media</Radio>
                            {valueAboutUs === 2 ? (
                                <Form.Item
                                    noStyle
                                    name="aboutUsSocialMedia"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Please select a social media",
                                        },
                                    ]}
                                >
                                    <Select
                                        style={{ width: 300, marginLeft: 10 }}
                                        options={[
                                            {
                                                value: "facebook",
                                                label: "Facebook",
                                            },
                                            {
                                                value: "instagram",
                                                label: "Instagram",
                                            },
                                            {
                                                value: "tiktok",
                                                label: "TikTok",
                                            },
                                            {
                                                value: "youtube",
                                                label: "Youtube",
                                            },
                                            {
                                                value: "whatsapp",
                                                label: "Whatsapp",
                                            },
                                        ]}
                                    />
                                </Form.Item>
                            ) : null}
                        </div>
                        <Radio value={3}>Friends</Radio>
                        <Radio value={4}>Online Search</Radio>
                        <div>
                            <Radio value={5}>Others</Radio>
                            {valueAboutUs === 5 ? (
                                <Form.Item
                                    noStyle
                                    name="aboutUsOthers"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Please enter how did you hear about us",
                                        },
                                    ]}
                                >
                                    <Input
                                        style={{ width: 300, marginLeft: 10 }}
                                        placeholder="Type here"
                                    />
                                </Form.Item>
                            ) : null}
                        </div>
                    </Space>
                </Radio.Group>
            </Form.Item>
            <Form.Item
                name="message"
                rules={[
                    {
                        required: true,
                        message: "Please enter your message.",
                    },
                ]}
            >
                <Input.TextArea
                    placeholder="Your message"
                    rows={6}
                    size="large"
                />
            </Form.Item>
            <div css={styles.remarkContainer}>
                <span css={[colourCss.textPrimary, typography.subContent]}>
                    By clicking on “Submit” you agree to Coursite’s{" "}
                    <Link href="/terms/service">
                        <a css={styles.textUnderline}>Terms of Use</a>
                    </Link>{" "}
                    and{" "}
                    <Link href="/terms/privacy">
                        <a css={styles.textUnderline}>Privacy Policy</a>
                    </Link>
                    .
                </span>
            </div>
            <div css={styles.buttonContainer}>
                <Button
                    size="large"
                    css={styles.button}
                    htmlType="submit"
                    type="primary"
                    loading={loading}
                >
                    Submit
                </Button>
            </div>
        </Form>
    );
};

export default EnquirySendForm;
