const emailjsURL = "https://api.emailjs.com/api/v1.0/email/send";

const SendEnquiry = ({
    senderName,
    senderEmail,
    senderPhone,
    senderMessage,
}) => {
    const params = {
        service_id: "mailgun",
        template_id: "template_jmFPH1KT",
        user_id: "user_CO65QQWXz4JjQJjBhbcCG",
        template_params: {
            sender_name: senderName,
            sender_email: senderEmail,
            sender_phone: senderPhone,
            message: senderMessage,
        },
    };

    const headers = {
        "Content-type": "application/json",
    };

    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(params),
    };

    return fetch(emailjsURL, options).then((response) => {
        if (response.ok) {
            return response;
        } else {
            return response.json();
        }
    });
};

export default SendEnquiry;
