import { Select } from "antd";
import { RuleObject } from "antd/lib/form";
import {
    parsePhoneNumberFromString,
    parseIncompletePhoneNumber,
} from "libphonenumber-js";
import dayjs from "lib/dayjs";

export const formatCents = (priceCents: number | undefined): string => {
    if (priceCents === 0) {
        return "RM 0.00";
    }
    if (!priceCents) {
        return "";
    } else {
        return "RM " + (priceCents / 100).toFixed(2);
    }
};

export const formatRinggit = (priceRinggit: number): string => {
    if (typeof priceRinggit === "string") {
        return "RM 0.00";
    }
    if (!priceRinggit) {
        return "";
    } else {
        return "RM " + priceRinggit.toFixed(2);
    }
};

export const validatePhoneNumber = (
    _: RuleObject,
    value: string,
): Promise<void> => {
    if (value && value !== "") {
        try {
            const phoneNumber = parsePhoneNumberFromString(value, "MY");
            if (phoneNumber && phoneNumber.isValid()) {
                return Promise.resolve();
            }

            return Promise.reject("Invalid Phone Number!");
        } catch (err) {
            return Promise.reject(err);
        }
    }

    return Promise.resolve();
};

export const validatePriceNumber = (
    _: RuleObject,
    priceRinggit: string | number | null,
): undefined | Promise<void> => {
    if (typeof priceRinggit !== "number") {
        return Promise.reject("Invalid price");
    }
    if (priceRinggit < 0) {
        return Promise.reject("Price cannot be below RM0");
    }
    return Promise.resolve();
};

export const formatPhoneNumber = (value: string): string => {
    if (!value) {
        return value;
    }

    const formattedNumber = parseIncompletePhoneNumber(value);

    try {
        const phoneNumber = parsePhoneNumberFromString(formattedNumber, "MY");

        if (phoneNumber && phoneNumber.isValid() === true) {
            return phoneNumber.format("E.164");
        }
    } catch (err) {
        return formattedNumber;
    }

    return formattedNumber;
};

export const convertStringToTitleCase = (string: string): string => {
    if (!string) {
        return "";
    } else {
        const titleString = string
            .split(" ")
            .map((x) => {
                if (!x[0]) {
                    return x;
                }
                return x[0].toUpperCase() + x.slice(1).toLowerCase();
            })
            .join(" ");
        return titleString;
    }
};

export const formatDateRange = (startDt?: string, endDt?: string): string => {
    if (!startDt || !endDt) return "";
    const sd = formatDate(startDt);
    const ed = formatDate(endDt);
    const isSingleDay = sd == ed;
    if (isSingleDay)
        return `${sd} ${formatTime(startDt)} - ${formatTime(endDt)}`;
    return `${sd} - ${ed}`;
};

export const formatTime = (d: string): string => {
    return dayjs(d).tz().format("hh:mm a");
};

export const formatDate = (d: string): string => {
    return dayjs(d).tz().format("DD MMMM YYYY");
};

export function getTimeDistance(
    type: "today" | "week" | "month" | "year",
): [dayjs.Dayjs, dayjs.Dayjs] | undefined {
    const now = dayjs().tz();
    if (type === "today") {
        return [now.startOf("day"), now.endOf("day")];
    }
    if (type === "week") {
        return [now.startOf("week"), now.endOf("week")];
    }
    if (type === "month") {
        return [now.startOf("month"), now.endOf("month")];
    }
    return [now.startOf("year"), now.endOf("year")];
}

export const isItemInCart = (
    cartItems: { uid: string }[] | undefined,
    itemUid: string,
): boolean => !!cartItems?.some((item) => item.uid === itemUid);

export const generateDurationOption = (): JSX.Element[] => {
    // In Minutes
    const possibleDurations = [];
    const intervals = 15; // 30 minutes
    const minMinutes = 0.25 * 60;
    const maxMinutes = 15 * 60;
    for (let i = minMinutes; i <= maxMinutes; i += intervals) {
        possibleDurations.push(i);
    }
    return possibleDurations.map((inMinutes) => {
        const hours = Math.floor(inMinutes / 60);
        let label = hours === 0 ? "" : `${hours} hours `;
        label += `${inMinutes % 60} minutes`;
        return (
            <Select.Option key={inMinutes} value={inMinutes}>
                {label}
            </Select.Option>
        );
    });
};

export const sortBookingsByNameAndDate = <
    T extends { resources?: { name?: string }[]; startDt: string },
>(
    bookings: T[],
): T[] =>
    bookings
        .flatMap(
            (b) =>
                b.resources?.map((r) => ({ ...b, name: r.name || "" })) ?? [],
        )
        // date string should be ISO8601 so the string is sortable
        .sort((a, b) => a.startDt.localeCompare(b.startDt) || byName(a, b));

type HasName = { name: string };
export const byName = <T extends HasName>(a: T, b: T): number =>
    a.name.localeCompare(b.name, undefined, { numeric: true });

export const isUniqueBy = <T, U>(
    getter: (v: T) => U,
): ((v: T, i: number, arr: T[]) => boolean) => {
    return (current, i, arr) => {
        return arr.findIndex((v) => getter(v) === getter(current)) === i;
    };
};

/**
 * Returns unique values of an array based on Same-Value-Zero equality (uses `Set` internally)
 *
 * Example usage:
 * ```ts
 * const cities = ["KL", "KL", "PJ", "SJ"];
 *
 * console.log(unique(cities));
 * // ["KL", "PJ", "SJ"]
 * ```
 * @param arr array to get unique values of
 * @returns new array with only unique values based on Same-Value-Zero equality
 */
export const unique = <T,>(arr: T[]): T[] => Array.from(new Set(arr));
