import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import customParseFormat from "dayjs/plugin/customParseFormat";
import updateLocale from "dayjs/plugin/updateLocale";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);
dayjs.tz.setDefault("Asia/Kuala_Lumpur");
dayjs.extend(updateLocale);
dayjs.updateLocale("en", {
    weekStart: 0,
});

/* 
The function is designed to replace the time zone of the input datetime 
to "Asia/Kuala_Lumpur" time zone without changing the datetime.
*/
export const centreTZ = (date: dayjs.Dayjs): dayjs.Dayjs => {
    const stripTZDate = date.format("YYYY-MM-DD HH:mm:ss:SSS");
    const parseTZDate = dayjs.tz(
        stripTZDate,
        "YYYY-MM-DD HH:mm:ss:SSS",
        "Asia/Kuala_Lumpur",
    );
    return parseTZDate;
};

export default dayjs;
